/* eslint-disable import/prefer-default-export */
import {
  ataccDomainResetPassword as baseUrl,
} from '@/utils/paths'

import axios from 'axios'

export function resetPasswordResource() {
  function sendEmailResetPassword(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseUrl}email/reset-password`, payload)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function resetPassword(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseUrl}usuario/reset-password`, payload)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function validateToken(token) {
    // creo una nueva instancia de axios para que no interfieran los interceptores por defecto
    const newAxios = axios.create()
    const config = { headers: { 'X-AUTHENTICATION-TOKEN': token } }
    return new Promise((resolve, reject) => {
      newAxios.post(`${baseUrl}usuario/validate-token`, null, config)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    sendEmailResetPassword,
    resetPassword,
    validateToken,
  }
}

<template>
  <div class="auth-wrapper auth-v1 px-2">
    <!--class="auth-inner py-2"-->
    <overlay-default
      id="olyForgotPassword"
      :show="isLoading"
    >
      <div>
        <b-card class="mb-0">
          <b-card-title class="text-center mb-1">
            <logo-component class="mb-1" />
            ¿Olvidó su contraseña?
          </b-card-title>
          <b-card-text class="text-center mb-2">
            <small>Ingrese su C.U.I.L. y le enviaremos un correo</small>
            <br>
            <small>con las instrucciones para restablecer su contraseña.</small>
          </b-card-text>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- cuil -->
              <b-form-group label-for="login-cuil">
                <validation-provider
                  name="Cuil"
                  rules="required|min:11"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <cleave
                      id="block"
                      v-model="formUser.cuil"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      :raw="true"
                      :options="options.customDelimiter"
                      placeholder="C.U.I.L."
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >C.U.I.L. obligatorio.
                    </small>
                    <small
                      v-if="failedRules.min"
                      class="text-danger"
                    >C.U.I.L. incompleto.
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
              >
                Establecer contraseña
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Volver al login
            </b-link>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <small>{{ versionApp }}</small>
          </b-card-text>
        </b-card>
        <!-- /Forgot Password v1 -->
      </div>
      <div v-if="showDetalleAsignacionModal">
        <modal-custom-default
          :show-modal-success-reset-password="showDetalleAsignacionModal"
          :modal-info="modalInfo"
          @contentModal="contentModal"
        />
      </div>
    </overlay-default>
  </div>
</template>

<script>
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BForm, BButton,
} from 'bootstrap-vue'
import { required, min } from '@validations'
import Cleave from 'vue-cleave-component'
import { resetPasswordResource } from '@/services/resetPasswordResource'
import LogoComponent from './LogoComponent.vue'
import ModalCustomDefault from './ModalCustomDefault.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BButton,
    BForm,
    Cleave,
    LogoComponent,
    ModalCustomDefault,
  },
  data() {
    return {
      versionApp: 'v 1.3.0',
      userEmail: '',
      // validation
      required,
      showDetalleAsignacionModal: false,
      isLoading: false,
      min,
      formUser: {
        cuil: null,
      },
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
      },
      modalInfo: {
        modalTitle: '',
        emailTo: '',
        defMessage: '',
      },
    }
  },
  methods: {
    contentModal(show) {
      this.showDetalleAsignacionModal = show
      this.$router.push({ name: 'login' })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // this.$router.push({ name: 'auth-reset-password-v1' })
          this.sendEmailResetPassword()
        }
      })
    },
    sendEmailResetPassword() {
      this.isLoading = true
      resetPasswordResource().sendEmailResetPassword(this.formUser)
        .then(resultsLoginUser => {
          // this.effectiveLogin(resultsLoginUser)
          this.modalInfo.modalTitle = 'Establecer contraseña'
          this.modalInfo.defMessage = `Hemos enviado un correo a ${resultsLoginUser.email} para finalizar el proceso de restablecimiento de contraseña`
          this.modalInfo.emailTo = resultsLoginUser.email
          this.showDetalleAsignacionModal = true
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<template>
  <div>
    <b-modal
      id="modal-default"
      v-model="showModalSuccessResetPassword"
      ok-title="Aceptar"
      :ok-only="true"
      centered
      button-size="sm"
      :title="modalInfo.modalTitle"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @ok="okModal"
    >
      <!--<b-card-text>Hemos enviado un correo a su casilla {{ emailTo }}</b-card-text>-->
      <b-card-text>{{ modalInfo.defMessage }}</b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue'

export default {
  name: 'ModalCustomDefault',
  components: {
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    showModalSuccessResetPassword: {
      type: Boolean,
      required: true,
      default: false,
    },
    modalInfo: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      none: false,
    }
  },
  created() {
  },
  methods: {
    okModal(bvModalEvt) {
      this.$emit('contentModal', false)
      bvModalEvt.preventDefault()
    },
  },
}
</script>
